import baseX from "base-x";

// Random shuffled string of 123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ
const alphabet = "i5xC8Y7gerdsKuGNmVbjLkZQTMRfqJ942cwXUWhzDn6P3BvAE1tapoHFyS";

const base58 = baseX(alphabet);

const isValidBase58 = (input: string): boolean => {
  for (const char of input) {
    if (alphabet.indexOf(char) === -1) {
      return false; // unknown char
    }
  }
  // returns true when the string is not empty (therefore checked by the loop above)
  return input.length > 0;
};

const hexToBase58 = (input: string): string => {
  return base58.encode(Buffer.from(input, "hex"));
};

const base58ToHex = (input: string): string => {
  if (!isValidBase58(input)) {
    throw new TypeError("No valid input for base58 given.");
  }
  return base58.decode(input).toString("hex");
};

export { isValidBase58, hexToBase58, base58ToHex };
