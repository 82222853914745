import { IncomingMessage } from "http";
import { hexToBase58 } from "./base58";
import { AttendanceListDoc } from "../models/AttendanceListModel";
import { NextApiRequest } from "next";
import { getCookie } from "./cookie";

/**
 * Returns the current base url of the application.
 * Note: the trailing slash is not included.
 * @param options
 */
export const getOriginUrl = (options?: {
  req?: IncomingMessage;
  onlyHost?: boolean;
}): string => {
  return typeof window !== "undefined"
    ? options?.onlyHost
      ? window.location.host
      : window.location.origin
    : (options?.onlyHost
        ? options?.req?.headers.host || ""
        : ensureString(
            options?.req?.headers["x-forwarded-proto"] ||
              "http" + (process.env.NODE_ENV !== "development" ? "s" : "")
          ) + "://") + (options?.req?.headers.host || "");
};

/**
 * Returns the first element form a string array or just the string.
 * @param value
 */
export function ensureString(value: string | string[]): string {
  if (Array.isArray(value)) {
    return value[0];
  } else {
    return value;
  }
}

/**
 * Compares two strings in a timing attack safe manner
 * @param a
 * @param b
 */
export const compareStringTimingAttackSafe = (
  a: string,
  b: string
): boolean => {
  let mismatch = 0;
  for (let i = 0; i < a.length; ++i) {
    mismatch |= a.charCodeAt(i) ^ b.charCodeAt(i);
  }
  return mismatch === 0 && a.length === b.length;
};

/**
 * Returns a value from local storage.
 * @param key
 * @param defaultValue
 * @return The value available in local storage or null.
 */
export const getFromLocalStorage = (
  key: string,
  defaultValue: string | null = null
): typeof defaultValue | string => {
  // Todo: make sure the user consents with local storage usage
  if (typeof window === "undefined") {
    // server side
    return defaultValue;
  }
  return window.localStorage.getItem(key) || defaultValue;
};

/**
 * Set a value in local storage.
 * @param key
 * @param value
 */
export const setLocalStorage = (key: string, value: string): void => {
  // TODO: make sure the user consents with local storage usage
  if (typeof window === "undefined") {
    // server side do nothing
    return;
  }
  window.localStorage.setItem(key, value);
};

/**
 * Returns the list share url given the list ID.
 * @param listId
 * @param req
 */
export const getListShareUrl = (
  listId: string,
  req?: IncomingMessage
): string => {
  return `${getOriginUrl({ req })}/l/${listId}`;
};

/**
 * Returns the admin url for the given list data
 * @param listId
 * @param adminKey
 * @param req
 */
export const getListAdminUrl = (
  listId: string,
  adminKey: string,
  req?: IncomingMessage
): string => {
  return `${getOriginUrl({ req })}/list/${listId}${adminKey}/admin`;
};
/**
 * Get the name of the admin cookie for the given list
 * @param list
 */
export const getAdminCookieName = (
  list: AttendanceListDoc | string
): string => {
  let listId: string;
  if (typeof list !== "string") {
    listId = hexToBase58(list.id);
  } else {
    listId = list;
  }
  return `admin${listId}`;
};

/**
 * Checks if the current request contains the admin token for the given list.
 * Server side
 * @param list
 * @param req
 */
export function isAdminServer(
  list: AttendanceListDoc,
  req: NextApiRequest
): boolean {
  const cookieName = getAdminCookieName(list);
  const cookieValue = getCookie(cookieName, req);
  if (cookieValue !== null) {
    return compareStringTimingAttackSafe(list.adminKey, cookieValue);
  }
  return false;
}

/**
 * Checks for existence of the admin cookie. Does not check if the value is valid.
 * @param listId
 */
export function isAdminClient(listId: string): boolean {
  return (
    typeof window !== "undefined" &&
    getCookie(getAdminCookieName(listId)) !== null
  );
}
