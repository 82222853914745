import * as React from "react";
import {
  DetailedHTMLProps,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import styles from "styles/form.module.css";
import { ClipBoardCheckIcon, ClipBoardIcon } from "./icons";

type BaseInputProps = {
  label?: string;
  description?: ReactNode;
  error?: string;
};

type TextInputProps = BaseInputProps &
  Partial<
    Omit<
      DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      "className" | "type" | "ref"
    >
  > & {
    type:
      | "date"
      | "datetime"
      | "datetime-local"
      | "email"
      | "month"
      | "number"
      | "password"
      | "search"
      | "tel"
      | "text"
      | "time"
      | "url"
      | "week";
  };
/**
 * Input element for types:
 * date, datetime, datetime-local, email, month, number, password, search, tel, text, time, url, week
 */
export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(
    { label = "", description = "", error = "", ...inputProps },
    forwardedRef
  ) {
    return (
      <label className="block my-4 text-base text-left sm:my-6 sm:flex sm:items-start sm:justify-between sm:text-lg">
        <span
          className={`sr-only ${styles.alwaysTextSize} sm:not-sr-only sm:inline sm:w-1/4 md:w-1/4 lg:w-1/6 sm:align-top sm:mt-2`}
        >
          {label}
        </span>
        <span className="w-full">
          <input
            ref={forwardedRef}
            {...inputProps}
            className={`${
              error
                ? "bg-red-200 hover:bg-red-100"
                : "bg-orange-100 hover:bg-orange-50"
            } block w-full text-base rounded-md border-transparent border-2 focus:border-yellow-500 focus:bg-white focus:ring-0 sm:text-lg`}
          />
          <span
            className={`${error ? "text-red-500" : ""} ${
              styles.alwaysTextSize
            } block ml-4 text-sm sm:text-base`}
          >
            {error ? error : description}
          </span>
        </span>
      </label>
    );
  }
);

type CheckedInputProps = BaseInputProps &
  Partial<
    Omit<
      DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      "className" | "type" | "ref"
    >
  > & {
    type: "checkbox" | "radio";
  };
/**
 * Input element for types:
 * checkbox, radio
 */
export const CheckedInput = React.forwardRef<
  HTMLInputElement,
  CheckedInputProps
>(function CheckedInput(
  { label = "", description = "", error = "", ...inputProps },
  forwardedRef
) {
  return (
    <label className="block my-4 text-base text-left sm:my-6 sm:flex sm:items-start sm:justify-between sm:text-lg">
      <span
        className={`sr-only ${styles.alwaysTextSize} sm:not-sr-only sm:inline sm:invisible sm:w-1/4 md:w-1/4 lg:w-1/6 sm:align-top sm:mt-2`}
      >
        {label}
      </span>
      <span className="w-full cursor-pointer">
        <input
          ref={forwardedRef}
          {...inputProps}
          className={`${
            error ? "bg-red-200" : "bg-orange-100"
          } mx-2 h-4 w-4 text-yellow-500 rounded border-transparent focus:border-transparent focus:bg-orange-100 focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-0 sm:h-6 sm:w-6`}
        />
        <span>{description}</span>
        <span
          className={`${error ? "text-red-500" : ""} ${
            styles.alwaysTextSize
          } block ml-8 text-sm sm:text-base`}
        >
          {error ? error : ""}
        </span>
      </span>
    </label>
  );
});

type TextareaProps = BaseInputProps &
  Partial<
    Omit<
      DetailedHTMLProps<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
      >,
      "ref" | "className"
    >
  >;
/**
 * Textarea element
 */
export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  function Textarea(
    { label = "", description = "", error = "", ...textareaProps },
    forwardedRef
  ) {
    return (
      <label className="block my-4 text-base text-left sm:my-6 sm:flex sm:items-start sm:justify-between sm:text-lg">
        <span
          className={`sr-only ${styles.alwaysTextSize} sm:not-sr-only sm:inline sm:w-1/4 md:w-1/4 lg:w-1/6 sm:align-top sm:mt-2`}
        >
          {label}
        </span>
        <span className="w-full">
          <textarea
            ref={forwardedRef}
            {...textareaProps}
            className={`${
              error
                ? "bg-red-200 hover:bg-red-100"
                : "bg-orange-100 hover:bg-orange-50"
            } block w-full resize-y text-base rounded-md border-transparent border-2 focus:border-yellow-500 focus:bg-white focus:ring-0 sm:text-lg`}
          />
          <span
            className={`${error ? "text-red-500" : ""} ${
              styles.alwaysTextSize
            } block ml-4 text-sm sm:text-base`}
          >
            {error ? error : description}
          </span>
        </span>
      </label>
    );
  }
);

type ButtonProps = Partial<
  Omit<
    DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "className" | "ref"
  >
> & {
  primary?: boolean;
  className?: string;
  children: ReactNode;
};

export function Button({
  primary = false,
  className = "",
  children,
  ...buttonProps
}: ButtonProps): JSX.Element {
  const primaryClasses = "bg-orange-200 hover:bg-yellow-500 hover:text-white";
  const secondaryClasses =
    "bg-warm-gray-200 hover:bg-warm-gray-600 hover:text-white";
  return (
    <button
      {...buttonProps}
      className={`${
        primary ? primaryClasses : secondaryClasses
      } ${className} p-3 font-header font-bold text-base tracking-wide rounded-lg transition-colors duration-200 focus:outline-none focus:ring-offset-2 focus:ring-2 focus:ring-orange-500 sm:text-lg sm:px-6 sm:py-4`}
    >
      {children}
    </button>
  );
}
type ClipboardButtonProps = Partial<
  Omit<
    DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "ref" | "onClick"
  >
> & {
  copyText: string;
  iconClassName: string;
  duration?: number;
};
export function ClipboardButton({
  copyText,
  iconClassName,
  duration = 2000,
  ...buttonProps
}: ClipboardButtonProps): JSX.Element {
  const [copyDone, setCopyDone] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (copyDone) {
      timerId = setTimeout(() => setCopyDone(false), duration);
    }
    return () => clearTimeout(timerId);
  }, [copyDone, duration]);

  const copyTextToClipboard = useCallback(() => {
    navigator.clipboard.writeText(copyText);
    setCopyDone(true);
  }, [copyText]);
  return (
    <button {...buttonProps} onClick={copyTextToClipboard}>
      {copyDone ? (
        <ClipBoardCheckIcon className={iconClassName} />
      ) : (
        <ClipBoardIcon className={iconClassName} />
      )}
    </button>
  );
}
