import { createHash, randomBytes } from "crypto";
import { hexToBase58 } from "./base58";

/**
 * Returns a sha256 signature of the given value.
 * @param value
 */
export function getSignature(value: string): string {
  return createHash("sha256").update(value).digest("hex");
}

/**
 * Returns a random admin key, base58 encoded
 */
export const generateAdminKey = (): string => {
  const ADMIN_KEY_LENGTH = 6;
  const key = hexToBase58(randomBytes(8).toString("hex"));
  return key.padStart(ADMIN_KEY_LENGTH, key[0]).slice(-1 * ADMIN_KEY_LENGTH); // ensure 6 char length.
};
