import getLogger from "./logger";
import { NextApiResponse } from "next";
import { compareStringTimingAttackSafe } from "./utils";
import { IncomingMessage } from "http";
import { getSignature } from "./crypto";

const logger = getLogger("cookie");

export enum CookieConsentType {
  FUNCTIONAL,
  PERFORMANCE,
  TRACKING,
}

export type NextRequest = IncomingMessage & {
  cookies?: { [p: string]: unknown };
};

export function getCookieConsent(): CookieConsentType[] {
  // TODO: implement logic
  // For now, only allow functional cookies
  return [CookieConsentType.FUNCTIONAL];
}

/**
 * Create javascript cookie
 * @param key           Cookie name
 * @param value         Cookie value
 * @param duration      Expiration time from now in ms. Null for a session cookie
 * @param consentType   Type of consent that the user needs to give to accept this cookie
 * @param res           [Optional] for server side cookies
 * @param httpOnly      [Optional] whether the cookie should only be accessible by the server.
 */
export function setCookie(
  key: string,
  value: string,
  duration: number | null,
  consentType: CookieConsentType,
  res?: NextApiResponse,
  httpOnly?: boolean
): void {
  if (!getCookieConsent().includes(consentType)) {
    logger.info("Consent for cookie '%s' has not been given.", key);
    return;
  }
  const expirationDate =
    duration === null ? duration : new Date(new Date().getTime() + duration);
  if (typeof window !== "undefined") {
    setCookieBrowser(key, value, expirationDate);
  } else if (typeof res !== "undefined") {
    setCookieServer(
      key,
      value,
      expirationDate,
      typeof httpOnly === "undefined" ? true : httpOnly,
      res
    );
  } else {
    logger.error(
      "Try to set server side cookie without supplying a response object.",
      { cookieName: key }
    );
  }
}

/**
 * Delete cookie by setting their expiration date in the past and their value to an empty string.
 * @param key
 * @param res
 */
export function deleteCookie(key: string, res?: NextApiResponse): void {
  const expirationDate = new Date(0);
  if (typeof window !== "undefined") {
    setCookieBrowser(key, "", expirationDate);
  } else if (typeof res !== "undefined") {
    setCookieServer(key, "", expirationDate, false, res);
  } else {
    logger.error(
      "Try to delete server side cookie without supplying a response object.",
      { cookieName: key }
    );
  }
}

/**
 * Returns a cookie value if exists. null otherwise.
 * @param key
 * @param req
 */
export function getCookie(key: string, req?: NextRequest): string | null {
  if (typeof window !== "undefined") {
    const res = document.cookie.replace(
      new RegExp(
        `(?:(?:^|.*;\\s*)${getCookieName(key)}\\s*\\=\\s*([^;]*).*$)|^.*$`
      ),
      "$1"
    );
    return res.length > 0 ? decodeCookieValue(res) : null;
  } else if (typeof req !== "undefined") {
    const cookie = req.cookies && req.cookies[getCookieName(key)];
    if (typeof cookie === "string") {
      return cookie || null;
    }
    return null;
  } else {
    logger.error(
      "Try to get server side cookie without supplying a request object.",
      { cookieName: key }
    );
    return null;
  }
}

/**
 * Sets a signed cookie on the server side.
 *
 * A signed cookie sets actually 2 cookies: one with the value and one with a signature of the value.
 * This first cookie is therefore accessible and readable by javascript on the client.
 * @param res
 * @param key
 * @param value
 * @param duration
 * @param consentType
 */
export function setSignedCookie(
  res: NextApiResponse,
  key: string,
  value: string,
  duration: number,
  consentType: CookieConsentType
): void {
  if (!getCookieConsent().includes(consentType)) {
    logger.info("Consent for cookie '%s' has not been given.", key);
    return;
  }
  if (typeof res === "undefined" || typeof window !== "undefined") {
    logger.error(
      "Try to set signed cookie on client side or without response object given.",
      { cookieName: key }
    );
    return;
  }
  const signedCookieKey = getSignedCookieKey(key);
  const signedValue = getSignature(value + process.env.APP_SECRET);
  const expirationDate =
    duration === null ? duration : new Date(new Date().getTime() + duration);
  setCookieServer(signedCookieKey, signedValue, expirationDate, true, res);
  setCookieServer(key, value, expirationDate, false, res);
}

/**
 * Returns cookie value of a signed cookie if the signature is valid.
 * @param req
 * @param key
 */
export function getSignedCookie(req: NextRequest, key: string): string | null {
  const signedCookieKey = getSignedCookieKey(key);
  const cookieValue = getCookie(key, req);
  const signedCookieValue = getCookie(signedCookieKey, req);
  if (null === cookieValue || null === signedCookieValue) {
    return null;
  }
  const expectedSignature = getSignature(cookieValue + process.env.APP_SECRET);
  if (!compareStringTimingAttackSafe(expectedSignature, signedCookieValue)) {
    logger.info("Signature for signed cookie '%s' is not valid.", key);
    return null;
  }
  return cookieValue;
}

/**
 * Deletes the signed cookie
 * @param res
 * @param key
 */
export function deleteSignedCookie(res: NextApiResponse, key: string): void {
  const signedCookieKey = getSignedCookieKey(key);
  deleteCookie(signedCookieKey, res);
  deleteCookie(key, res);
}

/**
 * Actual browser implementation to set cookies at the client.
 *
 * @param key
 * @param value
 * @param expirationDate
 */
function setCookieBrowser(
  key: string,
  value: string,
  expirationDate: Date | null
) {
  document.cookie = serializeCookie(key, value, expirationDate);
}

/**
 * Actual server implementation to set cookies at the server
 * @param key
 * @param value
 * @param expirationDate
 * @param httpOnly
 * @param res
 */
function setCookieServer(
  key: string,
  value: string,
  expirationDate: Date | null,
  httpOnly: boolean,
  res: NextApiResponse
) {
  if (res.headersSent) {
    logger.error(
      "Unable to set cookies on the server after the headers are sent.",
      { cookieName: key }
    );
    return;
  }
  let currentCookies =
    (res.hasHeader("Set-Cookie") ? res.getHeader("Set-Cookie") : []) || [];
  if (!Array.isArray(currentCookies) && typeof currentCookies !== "string") {
    currentCookies = [];
  }
  if (typeof currentCookies === "string") {
    currentCookies = [currentCookies];
  }
  currentCookies.push(
    serializeCookie(key, value, expirationDate, { httpOnly })
  );
  res.setHeader("Set-Cookie", currentCookies);
}

/**
 * Serializes cookie into string that can be parsed by the browser
 * @param key
 * @param value
 * @param expirationDate
 * @param flags
 */
function serializeCookie(
  key: string,
  value: string,
  expirationDate: Date | null,
  flags?: CookieFlags
): string {
  return `${getCookieName(key)}=${encodeCookieValue(value)}; ${
    expirationDate !== null ? `expires=${expirationDate.toUTCString()}; ` : ""
  }${getCookieFlags(flags)}`;
}

type CookieFlags = {
  path?: string;
  sameSite?: "lax" | "strict" | "none";
  httpOnly?: boolean;
};
/**
 * Generates the cookie flags from the given object
 * @param flags
 */
function getCookieFlags(flags?: CookieFlags): string {
  const attr = Object.assign(
    { path: "/", sameSite: "strict", httpOnly: false },
    flags
  );
  const pieces: string[] = [];
  if (attr.path) {
    pieces.push(`path=${attr.path}`);
  }
  if (attr.sameSite) {
    pieces.push(`SameSite=${attr.sameSite}`);
  }
  if (attr.httpOnly) {
    pieces.push("HttpOnly");
  }
  if (process.env.NODE_ENV === "production") {
    pieces.push("Secure");
  }
  return pieces.join("; ");
}

function getSignedCookieKey(key: string) {
  return `${key}__s`;
}

// Functions for transforming the cookie name/value to supported chars.
// NOTE: these transformations are also used by the Next.js request middleware.
function getCookieName(key: string): string {
  return encodeURIComponent(key);
}

function encodeCookieValue(value: string): string {
  return encodeURIComponent(value);
}

function decodeCookieValue(encoded: string): string {
  return decodeURIComponent(encoded);
}
